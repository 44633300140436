@import '../../app/styles/vars.module.scss';
@import '../../app/styles/themify.module.scss';

.drawer {
  width: var(sidebar--width);
  max-width: var(sidebar--maxWidth);
}

.sidebar__indicator {
  display: none;
}

.sidebar__tabs {
  border-bottom: 0;
}

.sidebar__tab {
  display: block;
  overflow: hidden;
  flex-grow: 1;
  min-width: 0;
  min-height: 0;
  height: var(sidebar__toolbar--height);
  line-height: var(sidebar__toolbar--height);
  opacity: 1;
  padding: 0 6px;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1;
  border-top: 0;
  @include themify() {
    background-color: theme(sidebar-tab__border--inactive);
    border-bottom: 1px solid theme(sidebar-tab__border);
    border-left: 1px solid theme(sidebar-tab__border);
  }

  &:hover {
    @include themify() {
      color: theme(color__text--primary);
    }
  }
}

.sidebar__tab-close {
  flex-grow: 0;
  margin: 0;
  padding: 0;
  width: var(sidebar__toolbar--height);
  height: var(sidebar__toolbar--height);
  border-bottom: 0;
  cursor: pointer;
  min-width: 0;
  @include themify() {
    border-bottom: 1px solid theme(sidebar-tab__border);
    border-left: 1px solid theme(sidebar-tab__border);
  }
}

.sidebar__tab--selected {
  @include themify() {
    color: theme(color--primary);
    background-color: theme(sidebar__background);
    border-bottom: 0;
  }
}

.sidebar__tab__label {
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  height: var(sidebar__toolbar--height);
}

.flag {
  display: flex;
  height: calc(#{var(sidebar__toolbar--height)} - 20px);
  align-items: center;
  justify-content: center;
  margin: 10px 0;
}

.flag__icon {
  height: 100% !important;
  width: auto !important;
}

.btn-close {
  display: flex;
  justify-content: center;
  align-items: center;
  height: var(sidebar__toolbar--height);
}

@import '../../../app/styles/vars.module.scss';
@import '../../../app/styles/mixin.module.scss';

.step__label {
  font-size: var('text--xxxl');
}

.step__label:global(.MuiStepIcon-completed) {
  cursor: pointer;
}

.stepper {
  padding: 24px 0px;
  width: calc(100% + 30px);
  left: -15px;
  position: relative;
}

.step {
  display: flex;
  align-content: center;
  justify-content: center;
}

.step__button {
  width: min-content;
}
.button__label {
  font-size: var('text--md');
  white-space: nowrap;
  display: none;
}

@media screen and (min-width: var('tablet')) {
  .stepper {
    padding: 24px 0px;
    width: 100%;
    left: unset;
    position: unset;
  }
}
@media screen and (min-width: var('phone')) {
  .button__label {
    display: block;
  }
}

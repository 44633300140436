.location {
  margin-top: 5px;
  margin-right: 20px;
}

.box {
  display: flex;
  align-items: center;
}

.flag__icon {
  margin-left: 10px;
}
.explain__heading {
  font-weight: 500;
}

.confirmation__heading {
  font-weight: 600;
  text-transform: uppercase;
}

.explain__item {
  padding: 5px;
  display: flex;
}

.explain__item::before {
  content: '';
  display: block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #000;
  margin-top: 7px;
  margin-right: 5px;
}

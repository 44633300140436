@import '../../../../app/styles/themify.module.scss';
@import '../../../../app/styles/vars.module.scss';

.mega-menu__item--level1,
.mega-menu__item--level2,
.mega-menu__item--level3,
.mega-menu__item--level4 {
  text-transform: uppercase;
  font-size: var(text--sm);
  font-weight: 500;
}

.mega-menu__item--level2:hover,
.mega-menu__item--level3:hover,
.mega-menu__item--level4:hover {
  text-decoration: underline;
}

.mega-menu__item--level1,
.mega-menu__item--level2 {
  font-weight: 600;
}

.mega-menu__item--level1 {
  flex: auto;
  flex-basis: 1%;
  display: block;
}

.mega-menu__tab {
  display: flex;
  margin: 0;
  height: var(menu-height);
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-size: var(text--md);
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 200ms ease-in;
  cursor: pointer;
  font-weight: 600;
  @include themify() {
    background-color: theme(mega-menu__background--level1);
    color: theme(mega-menu__text--level1);
  }
}

.mega-menu__item--hover {
  @include themify() {
    background: theme(mega-menu__background--level1--hover);
    color: theme(mega-menu__text--level1--hover);
  }
}

.mega-menu__item--active {
  @include themify() {
    background: theme(mega-menu__background--level1--active);
    color: theme(mega-menu__text--level1--active);
  }
}

.mega-menu__list {
  position: absolute;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  left: 0;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  transition: all 200ms ease-in;
  padding-right: 0;
  z-index: 128;
  @include themify() {
    background: theme(mega-menu__background);
  }
}

.mega-menu__list-item {
  padding-right: 20px;
  margin: 8px;
}

.mega-menu__item--special {
  @include themify() {
    color: theme(color--primary);
  }
}

.mega-menu__item--break {
  display: inline-block;
  flex-basis: 100%;
  width: 0;
  height: 0;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.mega-menu__list--active {
  opacity: 1;
  visibility: visible;
}

@import '../../app/styles/themify.module.scss';
@import '../../app/styles/vars.module.scss';

.collapse__container {
  position: absolute;
  width: 100%;
  z-index: 100;
  border-top-width: 0;
  padding: 35px 25px 35px 35px;
  @include themify() {
    background: theme(mini-cart__background);
    border: 1px solid theme(mini-cart__border);
  }
}

.collapse__wrapper {
  flex-grow: 1;
}

.collapse__wrapper-inner {
  padding: 0;
}

.mini-cart {
  display: flex;
  height: 100%;
  align-items: center;
  margin-right: 2px;
}

.mini-cart__link {
  display: flex;
  cursor: pointer;
}

.mini-cart__counter {
  display: flex;
  margin-left: 5px;
  align-items: center;
}

.mini-cart__panel {
  display: none;
}

.mini-cart__panel--arrow {
  position: absolute;
  top: 0;
  right: 20px;
  z-index: 101;
}

.mini-cart__panel--arrow:after,
.mini-cart__panel--arrow:before {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  -webkit-transition: all 3s ease-in-out;
  -ms-transition: all 3s ease-in-out;
  transition: all 3s ease-in-out;
  border-width: 0;
  margin-left: 0;
}

.mini-cart__panel--arrow:before {
  border-color: rgba(136, 183, 213, 0);
  border-width: 16px;
  margin-left: -16px;
  @include themify() {
    border-bottom-color: theme(mini-cart__border);
  }
}

.mini-cart__panel--arrow:after {
  border-color: rgba(194, 225, 245, 0);
  border-width: 15px;
  margin-left: -15px;
  @include themify() {
    border-bottom-color: theme(mini-cart__background);
  }
}

.mini-cart__list {
  padding-right: 10px;
  max-height: calc(100vh - 300px);
}

.mini-cart__summary {
  height: 150px;
  padding: 10px 10px 35px 220px;
  grid-template-columns: 1fr 20px 1fr;
  grid-template-rows: 0.75fr 1fr;
  display: grid;
}

.mini-cart__summary-text {
  text-transform: uppercase;
}

.mini-cart__summary-text:first-of-type,
.mini-cart__button {
  grid-column: 1;
}

.mini-cart__button {
  margin: 10px 0;
}

.mini-cart__summary-text:last-of-type,
.mini-cart__button:last-child {
  grid-column: 3;
  justify-self: flex-end;
}

.mini-cart__summary-text:last-of-type {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.mini-cart__subtotal {
  grid-column: 1;
  padding-right: 20px;
  justify-self: flex-end;
}

.mini-cart__price {
  grid-column: 2;
  justify-self: flex-end;
  padding-right: 10px;
}

.link__cart {
  display: flex;
}

@media only screen and (min-width: var('phone')) {
  .mini-cart {
    margin-right: 10px;
  }
}

@media only screen and (min-width: var('tablet')) {
  .mini-cart__panel {
    display: none;
    width: 600px;
    position: absolute;
    right: 0;
    top: 65px;
    z-index: 1000;
    @include themify() {
      background: theme(mini-cart__background);
    }
  }

  .mini-cart__panel--show {
    display: block;
  }

  .mini-cart__panel-content {
    padding: 35px 20px 0 35px;
    max-height: calc(100vh - 100px);
  }
}

@import './vars.module.scss';
@import './themify.module.scss';

:global {
  .MuiFormControl-root {
    margin-bottom: 10px;
  }

  .MuiFormControl-root .MuiFormControl-root {
    margin-bottom: 0px;
  }

  .MuiOutlinedInput-input {
    padding: 15px;
  }

  .PrivateNotchedOutline-legendLabelled-4 {
    height: 15px;
  }

  .MuiFormControl-root.MuiTextField-root {
    margin-top: 8px;
  }

  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    top: 0px;
  }

  .MuiTypography-h1 {
    font-size: var('text--h1');
    font-weight: normal;
  }

  .MuiTypography-h2 {
    font-size: var('text--5xl');
    font-weight: normal;
  }

  .MuiTypography-h3 {
    font-size: var('text--4xl');
    font-weight: normal;
  }

  .MuiTypography-h4 {
    font-size: var('text--xxxl');
    font-weight: normal;
  }

  .MuiTypography-h5 {
    font-size: var('text--xxl');
    font-weight: normal;
  }

  .MuiTypography-h6 {
    font-size: var('text--xl');
    font-weight: normal;
  }

  .MuiTypography-subtitle1 {
    font-size: var('text--lg');
    font-weight: normal;
  }

  .MuiTypography-subtitle2 {
    font-size: var('text--lg');
    font-weight: bold;
  }

  .MuiTypography-body1 {
    font-size: var('text--md');
    font-weight: normal;
  }

  .MuiTypography-body2 {
    font-size: var('text--md');
    font-weight: bold;
  }

  .MuiTooltip-tooltip {
    font-size: var('text--sm');
  }

  .MuiSelect-root.MuiSelect-select {
    padding: 18px 32px 12px 15px;
  }

  .MuiSelect-select:focus {
    background: unset;
  }

  .MuiTab-root {
    border-right-width: 0px;
    border-style: solid;
    border-width: 1px;
    &:last-child {
      border-right-width: 1px;
      border-right-style: solid;
    }
  }

  .MuiTab-root.Mui-selected {
    @include themify {
      background: theme(color--primary);
      border-color: theme(header-top-message__background);
      color: theme(color__text--primary);
      &:last-child {
        border-right-color: theme(header-top-message__background);
      }
    }
  }

  .MuiButtonBase-root.MuiMenuItem-root.Mui-selected {
    @include themify {
      background-color: theme(color--gray);
    }
  }

  .MuiTab-root .MuiTab-labelIcon {
    font-size: 0.9rem;
  }

  .MuiTab-root .MuiTouchRipple-child,
  .MuiTab-root .MuiTouchRipple-childPulsate,
  .MuiTab-root .MuiTouchRipple-ripplePulsate {
    @include themify {
      background: theme(color--primary);
    }
  }

  .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
    background-color: rgba(0, 0, 0, 0.05);
  }
}

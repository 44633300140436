@import '../../app//styles/vars.module.scss';

.content {
  display: flex;
  width: 100%;
  min-height: calc(100vh - 486px);
  padding: 15px;
  flex-direction: column;
}

.content--padding-top {
  padding-top: 15px;
}

@media (min-width: var('tablet')) {
  .content {
    padding: 0;
  }

  .content--padding-top {
    padding-top: 30px;
  }
}

.calendar__button {
  margin-top: 4px;
}

.actions {
  display: flex;
  flex-direction: row;
  padding: 15px 7px;
  width: 100%;
  justify-content: space-between;
}
